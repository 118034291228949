<template>
  <div class="text-general">
    <pdf-view :src="`${publicPath}cv.pdf`" title="cv.pdf" />

    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Certificates</h5>

        <ul>
          <li>
            CCNA:
            <a :href="`${publicPath}cert/ccna-1.pdf`">1</a>,
            <a :href="`${publicPath}cert/ccna-2.pdf`">2</a>,
            <a :href="`${publicPath}cert/ccna-3.pdf`">3</a>,
            <a :href="`${publicPath}cert/ccna-4.pdf`">4</a>
          </li>

          <li>
            SECOPS:
            <a :href="`${publicPath}cert/course-secfnd.pdf`">1</a>,
            <a :href="`${publicPath}cert/course-secops.pdf`">2</a>,
            <a
              href="https://www.youracclaim.com/badges/27e47c39-3096-4eb2-bb93-01dd25c503bc"
            >
              badge
            </a>
          </li>

          <li>
            MTA:
            <a :href="`${publicPath}cert/mta-Server.pdf`"
              >Windows Server Administration Foundations</a
            >,
            <a :href="`${publicPath}cert/mta-Security.pdf`"
              >Security Fundamentals</a
            >,
            <a :href="`${publicPath}cert/mta-Dev.pdf`"
              >Software Development Fundamentals</a
            >
          </li>

          <li>
            MongoDB University:
            <a :href="`${publicPath}cert/mongo.pdf`">MongoDB for Developers</a>
          </li>

          <li>
            Coursera:
            <ul>
              <li>
                <a :href="`${publicPath}cert/coursera-crypto.pdf`"
                  >Cryptography I</a
                >
              </li>
              <li>
                <a :href="`${publicPath}cert/coursera-gameth.pdf`"
                  >Game Theory</a
                >
              </li>
              <li>
                <a :href="`${publicPath}cert/coursera-datascience.pdf`"
                  >Introduction to Data Science</a
                >
              </li>
              <li>
                <a :href="`${publicPath}cert/coursera-bioinf1.pdf`">
                  Finding Hidden Messages in DNA (Bioinformatics I)
                </a>
              </li>
            </ul>
          </li>

          <li>
            LPI:
            <a :href="`${publicPath}cert/ndg-linux.pdf`"
              >NDG Linux Essentials</a
            >
          </li>

          <li>
            Schneider Energy University:
            <a :href="`${publicPath}cert/schneider-1.pdf`">1</a>,
            <a :href="`${publicPath}cert/schneider-2.pdf`">2</a>,
            <a :href="`${publicPath}cert/schneider-3.pdf`">3</a>,
            <a :href="`${publicPath}cert/schneider-4.pdf`">4</a>,
            <a :href="`${publicPath}cert/schneider-5.pdf`">5</a>,
            <a :href="`${publicPath}cert/schneider-6.pdf`">6</a>
          </li>
          <li>
            German:
            <a :href="`${publicPath}cert/german-b1.pdf`">B1</a>
          </li>
        </ul>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import PdfView from "@/components/PdfView";

export default {
  name: "CV",
  components: { PdfView },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped></style>
